/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import './assets/css/custom.css';
import 'react-credit-cards/es/styles-compiled.css';
// import './setupProxy.js'

const render = () => {
    import('./AppRenderer').then(() => {
        require('./AppRenderer');
    });
};

render();

import { createTheme, ThemeProvider,Collapse } from '@material-ui/core';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { StyleSheetManager } from 'styled-components';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateAdapter from '@material-ui/lab/AdapterDateFns';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import heSaLocale from 'date-fns/locale/he';



const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});


const theme = createTheme({
  direction: 'rtl',
  palette: {
    background: {
      default: "#efefef"
    },
    primary: {
      main: '#423c90'
    }
  }
});

ReactDOM.render(
  <CacheProvider value={cacheRtl}>
    <StyleSheetManager stylisPlugins={[rtlPlugin]}>
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={DateAdapter} locale={heSaLocale}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
            TransitionComponent={Collapse}>
            <ThemeProvider theme={theme}>
              <div dir="rtl" style={{ backgroundColor: '#efefef' }}>
                <RecoilRoot>
                  <Suspense fallback={<div className="loading" />}>
                    <App />
                  </Suspense>,
                </RecoilRoot>
              </div>
            </ThemeProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </StylesProvider>
    </StyleSheetManager>
  </CacheProvider>,

  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
